import logo from './logo.svg';
import './App.scss';


function create_type(type){
  const an = [
    "illustration"
  ]
  const title = type;
  const id = type;
  let a = "a"
  if (an.indexOf(id) >= 0){
    a = "an"
  }

  return {
    id,
    title,
    a,

  }
}

function create_types(types){
  if (typeof types == "string"){
    types = [types];
  }
  types = types.map(type => create_type(type))
  return types
}

function thing(types, title, teaser, url){
  
  types = create_types(types);
  return {
    title,
    types,
    teaser,
    url,
  };
}

function Frame(props){
  return (
    <div className="frame">
      {props.children}
    </div>
  )
}

const BussinAround = () => thing(
  "game",
  "Bussin' Around",
  "A card game about public transportation.",
  "https://bussin.net"
);

const YallBetcha = () => thing(
  "game",
  "YallBetcha",
  "A web-based game for web-based hangouts.",
  "https://yallbetcha.com"
);

const Dojour = () => thing(
  "webapp",
  "Dojour",
  "A platform for sharing and discovering events.",
  "https://dojour.us"
);

const Gather = () => thing(
  "webapp",
  "Gather", 
  "Replacing email for teams who need to stay connected.",
  "https://gather.io"
);




function App() {
  /*
  # User Acceptance Criteria:
  - As an admirer, I can read about projects, so I can get a quick understanding of the project
  - As an admirer, I can interact with the projects, so I can enjoy the qualities of the projects
  - As an admirer, I can easily identify the types of projects, so i can find something I'm interested in

  # Notes
  - What if this played like a movie
  */

  const thingsHeMakes = [
    "WebApps",
    "Videos",
    "Games",
    "Art",
  ];

  const things = [
    BussinAround(),
    YallBetcha(),
    Dojour(),
    Gather(),
    thing("webapp", "CourseBeyond",  "Create, sell and teach your own courses online.", "https://coursebeyond.com"),
    // thing("webapp", "The Great List",  "Determining the relative greatness of all things.", "https://coursebeyond.com"),
    thing("video", "The Jeffrey Company",  "Lets call it comedy.", "https://www.youtube.com/user/JeffreyUnion"),
    thing("video", "Best of Tophelan",  "Some of my favorite videos on my youtube channel", "https://www.youtube.com/playlist?list=PLwtdpQJFw4gJGn69o-2fv4hTQeT6QmqPA"),
    thing("video", "Lonely Child Music Video",  "Hand-drawn, computer assembled animation for the song Lonely Child by the artist Lonely Child.", "https://www.youtube.com/watch?v=2iFNdYoXH1Y"),
    thing("play", "Detached: The Return of the Pastor Brothers",  "August 7th, 2011 staging of the Jeffrey Company's first play.", "https://vimeo.com/28223648"),
    thing("video", "The Tale of the Frozen Ghost",  "2010 remake of an episode of 'Are You Afraid of the Dark?", "http://vimeo.com/11739564"),
    thing("illustration", "The Nine Billion Names of God",  "A comic based on the short story by Arthur C. Clarke", "https://imgur.com/gallery/bjuiQ"),
    // jeffrey xmas
    // but maybe just make jeffreywebsite.com a thing again and link off to that
    // perhaps you should just make the jeffreywebsite use the same tech as this one
    // use nginx to point to the sites to different html pages
  ];
  
  function title(thing){
    return (
      <a href={thing.url}>{thing.title}</a> 
    )
  }

  function title(thing){
    return (
      <a href={thing.url}>{thing.title}</a> 
    )
  }

  function teaser(thing){
    return (
      <p> {thing.teaser} </p>
    );
  }

  function mediums(thing){
    console.log(thing.types)
    return thing.types.map((type, index) => { 

      let result =  `${type.a} ${type.title}`;
      if (index > 0){
        result = `/${result}`;
      }
      console.log(type, index, result)
      return (
        result
      );
      });
    // return ({
    //   thing.types.map((thing, index) => {
    //     if (index) return (` ${thing}`)
    //     return (thing);
    //   })
    // });
  }

  return (
    <div className="App">
      <Frame>
      <header className="App-header">
        <h1>Things made by Timmer O'Phelan </h1>
        {/* <h2>
          {
            thingsHeMakes.map((thing, index) => {
              if (index) return (` ${thing}`)
              return (thing);
            })
          }
        </h2> */}
      </header>
      </Frame>
      {
        things.map(thing=>{
          return (
            <Frame>
              {title(thing)}
              {mediums(thing)}
              {teaser(thing)}
            </Frame>
          )
        })
      }
      
    </div>
  );
}

export default App;
